
// Georgia fonts
@font-face {
    font-family: 'Georgia';
    src: url("../assets/font/georgia/georgia-regular.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: 'Georgia';
    src: url("../assets/font/georgia/georgia-regular-italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Georgia';
    src: url("../assets/font/georgia/georgia-bold.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: 'Georgia';
    src: url("../assets/font/georgia/georgia-bold-italic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}
