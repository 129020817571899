.blog {
    .details {
        padding: 20px;

        img {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        h3,
        p {
            color: var(--black);
        }

        p {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
        }
    }
}