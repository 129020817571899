@import "./mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --white: #fff;
  --primary: #db2a5d;
  --secondary: #dba106;
  --black: #000000;
  --dark-gray: #424143;
  --gray: #e2e2e1;
  --light-gray: #f5f5f5;
  --success: #28a745;
  --green: #0bb479;
  --info: #17a2b8;
  --warning: #f9c90d;
  --danger: #dc3545;
  --yellow: #f9c90d;
}

$theme-colors: (
  "primary": #db2a5d,
  "secondary": #dba106
);

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

*,
html,
body {
  font-family: 'Roboto', sans-serif;
  // @include Georgia;
  line-height: 160%;
  letter-spacing: 0.2;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-size: 20px;
  line-height: 1.5;
  color: var(--dark-gray);
  text-align: left;
  background-color: #fff;
  overflow-x: hidden;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1 {
  font-size: 30px;

  @media(max-width: 700px) {
    font-size: 25px;
  }

  @media(max-width: 370px) {
    font-size: 22px;
  }
}

h2 {
  font-size: 25px;

  @media(max-width: 700px) {
    font-size: 20px;
  }

  @media(max-width: 370px) {
    font-size: 19px;
  }
}

h3 {
  font-size: 20px;

  @media(max-width: 700px) {
    font-size: 18px;
  }
}

h4,
h5,
h6 {
  font-size: 20px;

  @media(max-width: 700px) {
    font-size: 18px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'IBM Plex Serif', serif;
  // @include Georgia;
  font-weight: 700 !important;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 126% !important;
}

a {
  i {
    margin-left: 6px
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

p,
ol,
ul {
  font-size: 18px;

  @media(max-width: 300px) {
    font-size: 17px;
  }
}

p:last-child {
  margin-bottom: 0;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
}

.title-font {
  font-size: 30px !important;
}

.text-red {
  color: var(--primary)
}

.text-blue {
  color: var(--secondary)
}

.text-white {
  color: var(--white)
}

.section-padding {
  padding: 90px 0;
}

.section-bottom-padding {
  padding: 50px 0 90px 0;
}

.btn-gradient {
  background: linear-gradient(270deg, #d33743, #a30d18);
  box-shadow: none;
  color: #fff !important;
  border: 0 !important;
}

.ant-btn.btn-primary,
.ant-btn-primary {
  padding: 10px 20px;
  min-height: 40px;
  background: var(--primary);
  border-radius: 0;
  color: var(--white);
  box-shadow: none;
  border: 0;
  height: 40px;
}

.ant-btn.btn-primary:hover,
.ant-btn-primary:hover {
  background: var(--secondary);
}

.btn {
  border-radius: 0 !important;
}

.btn-link {
  color: var(--primary) !important;

  img {
    width: 30px;
    margin-left: 10px;
  }
}

.container {
  -webkit-flex: 0 0 1170px;
  -moz-box-flex: 0;
  -ms-flex: 0 0 1170px;
  flex: 0 0 1170px;
  max-width: 1170px;
}

// Modal box
.ant-modal-wrap {
  .ant-modal-title {
    color: var(--secondary);
    font-weight: 600;
    font-size: 23px;
    line-height: 150%;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-body {
    padding: 20px 37px;
  }
}

// background pattern starts
.border-radius {
  @include borderRadius;
}

.bg-light-red {
  background: #ffe0e2;
}

.bg-gray {
  background: #efefef;
}

// background color
.bg-blue {
  background-color: var(--secondary);
}

.bg-light-gray {
  background-color: var(--light-gray);
}

.bg-light {
  background-color: rgb(241, 240, 255);
}

.bg-red {
  background-color: var(--primary);
}

.bg-blue-gradient {
  @include blueGradient;
}

.bg-red-gradient {
  @include redGradient;
}



// forms
.custom-input-box,
.custom-selectox {
  margin-bottom: 10px;
}

.ant-input {
  padding: 7px 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 39px;
  padding: 6px 11px;
}

// completed-project
.completed-project {
  img {
    width: 80px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 37px;
    color: var(--primary);
  }

  .details {
    background-color: var(--white);
    border-radius: 10px;
  }
}

// some-features
.some-features {
  img {
    width: 60px;
    margin-bottom: 15px;
  }
}