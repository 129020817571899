@import "../../Style/mixin.scss";

.service-grid {
    .align-middle {
        padding: 40px;
        position: relative;

        h2,
        p,
        a {
            color: var(--white)
        }
    }

    .cast-iron-bg {
        position: relative;
        min-height: 551px;
        background: url('../../assets/images/home/cast-iron-grinding-powder.jpg');
        @include backgroundNoRepeat($position: top left);

        @media(max-width:600px) {
            min-height: 250px;
        }

        &:before {
            @include beforeContentLeft($opacity: 0.9, $background: var(--primary));
        }
    }

    .all-scrap-bg,
    .used-grinding-bg {
        min-height: 250px;
        position: relative;
    }

    .all-scrap-bg {
        background: url('../../assets/images/home/metal-recyclable-scrap.jpg');
        @include backgroundNoRepeat($position: top left);

        &:before {
            @include beforeContentLeft($opacity: 0.9, $background: var(--secondary));
        }
    }


    .used-grinding-bg {
        background: url('../../assets/images/home/used-grinding-wheel.jpg');
        @include backgroundNoRepeat($position: top left);

        &:before {
            @include beforeContentLeft($opacity: 0.9, $background: #673AB7);
        }
    }

}

.products {
    .card {
        img {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        .details {
            padding: 20px 20px;
            text-align: center;

            p {
                color: var(--black);
            }
        }
    }
}

.cta {
    background: url('../../assets/images/main-slider/wastage-Grinding-wheel.jpg');
    @include backgroundNoRepeat($position: top left);

    h4 {
        margin-bottom: 16px;
    }

    .phone {
        font-size: 40px;
        color: var(--primary);

        @media(max-width: 600px) {
            font-size: 30px;
        }
    }
}