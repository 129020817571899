@import '../Style/mixin.scss';

.footer {
    padding: 40px 0;
    background: #141414;

    p {
        font-size: 16px;

        a {
            color: var(--secondary);
        }
    }

    h3 {
        font-size: 20px;
        color: var(--secondary);
        margin-bottom: 20px;
    }

    img {
        height: 143px;
    }

    ul {
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 10px;

            a {
                color: var(--white);
                display: block;
            }
        }
    }

    .social-media {
        ul {
            list-style: none;

            li {
                a {
                    display: block;
                    margin-right: 16px;
                    font-size: 26px;
                    color: var(--white);

                    &:hover {
                        color: var(--primary);
                    }
                }
            }
        }
    }

    .border-t {
        border-top: 1px solid #333;
    }

    .contact-item {
        position: relative;
        padding-left: 33px;
        margin-bottom: 10px;

        .icon {
            position: absolute;
            top: 0;
            left: 0
        }

        i,
        span {
            color: var(--secondary);
            font-size: 16px;
        }

        p {
            margin-top: 2px;

            a {
                color: var(--white);
            }
        }
    }


    .footer-logo {
        @media(max-width: 1013px) {
            img {
                display: block;
                margin-bottom: 18px;
            }

            .border-right {
                border: unset !important;
            }
        }
    }

    .locations {
        span {
            padding: 0 10px;
            display: inline-block;
        }
    }

}

// back to top starts
.ant-back-top {
    right: 26px !important;
    bottom: 20px !important;

    @media(max-width: 450px) {
        width: 30px;
        height: 39px;
        right: 20px !important;
    }
}

.back-to-top {
    height: 45px;
    width: 40px;
    line-height: 30px;
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: 0 2px 10px rgba(41, 37, 97, 0.42);
    color: var(--primary);
    text-align: center;
    font-size: 15px;

    @media(max-width: 450px) {
        height: 39px;
        width: 30px;
        line-height: 27px;
        font-size: 11px;
    }

    img {
        width: 24px;
        height: 24px;
        margin-bottom: 4px;

        @media(max-width: 450px) {
            width: 12px;
            height: 12px;
        }
    }

    span {
        line-height: 0;
    }
}