@import "../../../Style/mixin.scss";

.sbi-slider {
  position: relative;

  .slider-content {
    display: block;

    .slider-caption {
      h1 {
        font-size: 42px;
        letter-spacing: 4px;
        font-weight: 400 !important;

        @media(max-width: 575px) {
          font-size: 18px;
          line-height: 162%;
        }
      }

      p {
        font-size: 82px;
        line-height: 140%;
        font-weight: 900;

        @media(max-width: 900px) {
          font-size: 41px;
        }

        @media(max-width: 600px) {
          font-size: 31px;
        }

        @media(max-width: 360px) {
          font-size: 24px;
        }
      }
    }
  }

  .center-contents {
    min-height: 100vh;
    position: relative;
    display: table;
    z-index: 1;

    @media(max-width:900px) {
      height: auto;
      min-height: 550px;
    }
  }

  .middle-content {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 9;
    outline: none;
  }

  .slider-1 {
    background: url('../../../assets/images/main-slider/sree-bala-murugan-industries-banner-1.jpg');
    @include backgroundNoRepeat($position: top left);
  }

  .slider-2 {
    background: url('../../../assets/images/main-slider/wastage-Grinding-wheel.jpg');
    @include backgroundNoRepeat($position: top left);
  }

  .slider-3 {
    background: url('../../../assets/images/main-slider/sree-bala-murugan-industries-banner-3.jpg');
    @include backgroundNoRepeat($position: top left);
  }

  .slider-4 {
    background: url('../../../assets/images/main-slider/sree-bala-murugan-industries-banner-2.jpg');
    @include backgroundNoRepeat($position: top left);
  }

  .slider-5 {
    background: url('../../../assets/images/main-slider/sree-bala-murugan-industries-banner-1.jpg');
    @include backgroundNoRepeat($position: top left);
  }

  .center-contents {
    &::before {
      @include beforeContent;
      background: #000000;
      opacity: 0.4;
    }
  }

  .slick-prev {
    left: unset;
    right: 91px;
  }

  .slick-next {
    right: 41px;
  }
}