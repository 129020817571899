@import "../../Style/mixin.scss";

.top-banner {
  position: relative;

  &::after {
    @include beforeContentLeft($background: #000, $opacity: 0.7);
  }

  .caption {
    width: 100%;
    position: relative;
    z-index: 1;

    h1 {
      font-size: 55px;
      letter-spacing: 4px;
      font-weight: 600;
      margin-bottom: 0;

      @media (max-width: 1018px) {
        font-size: 46px;
        line-height: 150%;
      }

      @media (max-width: 600px) {
        font-size: 43px;
      }

      @media (max-width: 500px) {
        font-size: 27px;
        letter-spacing: 0px;
      }

      @media (max-width: 300px) {
        font-size: 20px;
      }
    }

    p {
      font-size: 30px;
      word-break: break-word;

      @media (max-width: 900px) {
        font-size: 26px;
      }

      @media (max-width: 575px) {
        font-size: 20px;
      }
    }
  }

  .center-contents {
    min-height: 550px;
    position: relative;
    display: table;
    z-index: 1;

    @media (max-width: 1100px) {
      min-height: 350px;
    }

    .middle-content {
      display: table-cell;
      vertical-align: middle;
      position: relative;
      z-index: 9;
      outline: none;
    }
  }
}

.about-bg {
  background: url("../../assets/images/about-sree-balamurugan-industries.jpg");
  @include backgroundNoRepeat($position: top center);
}

.contact-bg {
  background: url("../../assets/images/main-slider/sree-bala-murugan-industries-banner-1.jpg");
  @include backgroundNoRepeat($position: top center);
}

.productservice-bg {
  background: url("../../assets/images/main-slider/wastage-Grinding-wheel.jpg");
  @include backgroundNoRepeat($position: top center);
}

.blog-bg,
.error-bg {
  background: url("../../assets/images/main-slider/wastage-Grinding-wheel.jpg");
  @include backgroundNoRepeat($position: top center);
}

.whatis-grindingwheel-bg,
.usedgrindingwheel-bg {
  background: url("../../assets/images/blog/best-grinding-wheel-scrap.jpg");
  @include backgroundNoRepeat($position: top center);
}

// inner pages banner

.castiron-grindingpowder-bg {
  background: url("../../assets/images/main-slider/sree-bala-murugan-industries-banner-3.jpg");
  @include backgroundNoRepeat($position: top center);
}

.allscrap-bg {
  background: url("../../assets/images/main-slider/all-kind-of-scrap-materials.jpg");
  @include backgroundNoRepeat($position: top center);
}

.millscale-bg {
  background: url("../../assets/images/main-slider/mill-scale-sree-balamurugan-industries.jpg");
  @include backgroundNoRepeat($position: top center);
}

.castironboring-bg {
  background: url("../../assets/images/main-slider/cast-iron-boring-scrap.jpg");
  @include backgroundNoRepeat($position: top center);
}

.termsandcondition-bg {
  background: url("../../assets/images/main-slider/wastage-Grinding-wheel.jpg");
  @include backgroundNoRepeat($position: top center);
}

.privacypolicy-bg {
  background: url("../../assets/images/main-slider/wastage-Grinding-wheel.jpg");
  @include backgroundNoRepeat($position: top center);
}
