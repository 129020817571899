// Georgia Font
@mixin Georgia {
  font-family: 'Georgia';
  font-weight: 400;
}

@mixin GeorgiaItalic {
  @include Georgia;
  font-style: italic;
}

@mixin GeorgiaBold {
  font-family: 'Georgia';
  font-weight: 600;
}

@mixin GeorgiaBoldItalic {
  @include GeorgiaBold;
  font-style: italic;
}

@mixin beforeContent ($content: "", $position: absolute, $top: 0, $left: 0, $right: unset, $bottom: unset, $width: 100%, $height: 100%, $opacity: 1) {
  content: $content;
  position: $position;
  top: $top;
  bottom: $bottom;
  right: $right;
  left: $left;
  width: $width;
  height: $height;
  opacity: $opacity;
}

@mixin beforeContentLeft($position: absolute, $top: 0, $left: 0, $right: unset, $bottom: unset, $width: 100%, $height: 100%, $background: #000, $opacity: 0.4) {
  content: "";
  position: $position;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
  width: $width;
  height: $height;
  background: $background;
  opacity: $opacity;
}

@mixin bgSetup ($bg-image: unset, $bg-repeat: no-repeat, $bg-position: center center, $bg-size: cover) {
  background: $bg-image;
  background-repeat: $bg-repeat;
  background-position: $bg-position;
  background-size: $bg-size;
}

@mixin boxShadow($color: rgba(215, 215, 215, 0.50)) {
  box-shadow: 0 2px 12px $color ;
}

@mixin backgroundNoRepeat ($repeat: no-repeat, $size: cover, $position: bottom center) {
  background-repeat: $repeat;
  background-size: $size;
  background-position: $position;
}

@mixin lightGraybg {
  background: #eaf3f8;
}

@mixin veryDarkGray {
  background-color: var(--very-dark-gray);
}

@mixin fullWidthHeight($width: 100%, $height: 100%) {
  width: $width;
  height: $height;
}

@mixin gradientText {
  color: #282561;
  display: block;
  background: -webkit-linear-gradient(#282561, #a30d18);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin redGradient{
  // background-image: linear-gradient(to right, #e31e28, #c8004f, #9b0067, #631f6d, #282561);
  background-image: linear-gradient(to right, #a30d18, #9b1f3e, #a31f3b, #631f6d, #282561);
}
@mixin blueGradient{
  background-image: linear-gradient(to right, #282561, #631f6d, #9b0067, #c8004f, #e31e28);
}

@mixin transitionDuration {
  transition-duration: .3s;
}

@mixin animationGrowHover {
  transform: scale(1.1);
}
@mixin animationGrow {
  transition-duration: .3s;
  transition-property: transform;
}

@mixin borderRadius($radius: 10px) {
  border-radius: $radius  !important;
}

@mixin borderRadiusLeft($top: 13px, $bottom: 13px){
  border-top-left-radius: $top;
  border-bottom-left-radius: $bottom;
}
@mixin borderRadiusRight($top: 13px, $bottom: 13px){
  border-top-right-radius: $top;
  border-bottom-right-radius: $bottom;
}

@mixin fontAwesomeiconCont {
  content: "\f138";
  position: absolute;
  right: 15px;
  bottom: 27px;
  font-size: 27px;
  width: 30px;
  height: 30px;
  color: var(--white);
  font-family: fontawesome;
}

@mixin alignCenter {
  margin:0 auto;
  display: block;
}